import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import { Event } from '@bugsnag/js';

import { bugsnagClient } from 'helpers/bugsnagHelper';
import { getTheme } from 'helpers/themeHook';
import { changeLocale, clearCurrentDateFnsLocale } from 'helpers/localeHelper';

export const whitelist = () => {
  switch (getTheme()) {
    case 'diet':
      return [
        'en',
        'de',
        'lv',
        'lt',
        'ro',
        'hu',
        'es',
        'et',
        'ru',
        'fr',
        'hr',
        'gr',
        'sk',
        'cs',
      ];
    case 'keto':
      return [
        'en',
        'lt',
        'et',
        'cs',
        'es',
        'fr',
        'de',
        'ro',
        'el',
        'hu',
        'hr',
        'pl',
        'pt',
        'bg',
        'sk',
        'ru',
        'nl',
        'da',
        'it',
        'sr',
        'ka',
        'uk',
        'no',
        'sv',
        'fi',
        'he',
      ];
    case 'ketogo':
      return [
        'en',
        'lt',
        'et',
        'cs',
        'es',
        'fr',
        'de',
        'ro',
        'el',
        'hu',
        'hr',
        'pl',
        'pt',
        'bg',
        'sk',
        'ru',
        'nl',
        'no',
        'da',
        'it',
        'sr',
        'ka',
        'fi',
        'sv',
      ];
    case 'fasting':
      return [
        'en',
        'es',
        'cs',
        'uk',
        'hu',
        'no',
        'ro',
        'el',
        'fi',
        'bg',
        'pl',
        'sk',
        'fr',
        'he',
      ];
    case 'healthgenom':
      return ['en', 'es', 'it'];
    case 'monacofit':
    default:
      return [
        'en',
        'lv',
        'ru',
        'hr',
        'lt',
        'ro',
        'sk',
        'et',
        'fr',
        'es',
        'hu',
        'cs',
        'de',
        'gr',
      ];
  }
};

const backendOptions = {
  loadPath: '{{lng}}/{{ns}}.json',
  queryStringParams: { v: process.env.REACT_APP_VERSION },
  parse: (data: any) => data,
  request: (_: any, url: string, payload: any, callbackFn: any) => {
    const [locale, filename] = url.split('/');

    if (!locale || !filename) {
      return callbackFn({}, { status: 404 });
    }

    // eslint-disable-next-line
    const base = require(`./locales/${locale}/${filename}`);
    let theme = {};

    try {
      const currentTheme = getTheme();
      // eslint-disable-next-line
      theme = require(`./locales/${locale}/${currentTheme}/${filename}`);
      // eslint-disable-next-line no-empty
    } catch {}

    // eslint-disable-next-line consistent-return
    const customizer = (objValue: any, srcValue: any) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    mergeWith(base, theme, customizer);

    return callbackFn(null, {
      status: 200,
      data: base,
    });
  },
};

const initI18N = () => {
  (i18n as any)
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: backendOptions,
      ns: ['app'],
      defaultNS: 'app',
      fallbackNS: 'app',
      load: 'languageOnly',
      detection: { checkWhitelist: true },
      supportedLngs: whitelist(),
      saveMissing: true,
      returnObjects: true,
      languageOnly: 'languageOnly',
    });

  i18n.on('missingKey', (lngs, ns, key, res) => {
    bugsnagClient.notify(
      new Error(`Missing translation: ${res}`),
      (e: Event) => {
        e.severity = 'error';
        e.addMetadata('i18n', { lngs, ns, key });
      }
    );
  });

  i18n.on('failedLoading', (lng, ns, msg) => {
    bugsnagClient.notify(new Error(`Failed loading: ${msg}`), (e: Event) => {
      e.severity = 'error';
      e.addMetadata('i18n', { lng, ns });
    });
  });

  i18n.on('languageChanged', async (lng) => {
    const locale = lng.toLowerCase().substr(0, 2);

    try {
      await changeLocale(locale);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error(err);
      clearCurrentDateFnsLocale();

      bugsnagClient.notify(err, (e: Event) => {
        e.severity = 'error';
        e.addMetadata('dateFnsLocale', { url: `date-fns/locale/${locale}` });
      });
    }
  });

  return i18n;
};

initI18N();

export const getCurrentLocale = () => i18n.languages[0];

export const hasTranslation = (key: string) => i18n.exists(key);

export const getLocaleDirection = () => {
  const rtlLocales = ['he'];

  return rtlLocales.includes(i18n.language) ? 'rtl' : 'ltr';
};

export default i18n;
