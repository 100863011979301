import { defineAction } from 'redux-define';
import { LOADING, ERROR, SUCCESS, SENT } from 'actions/stateConstants';
import { UnsubscribeCommondata } from 'types/unsubscribe';
import { Cycle, Fasting, Timer } from 'types/fasting';
import { UnsubscribeReasons } from 'types/globalData';
import { AccessItems } from 'types/access';
import { GenomPlusSurveyDataType } from 'pages/GenomPlusSurvey/types';
import { InternalWorkoutState } from 'types/workouts';

export const CABLE_GET_URL = defineAction('CABLE_GET_URL', [SUCCESS, ERROR]);

// Calendar actions
export const CALENDAR_LOAD = defineAction('CALENDAR_LOAD', [SUCCESS, ERROR]);

export const CALENDAR_NO_PAST_DATA = 'CALENDAR_NO_PAST_DATA';
export const CALENDAR_NO_FUTURE_DATA = 'CALENDAR_NO_FUTURE_DATA';
export const CALENDAR_MORE_PAST_DATA = 'CALENDAR_MORE_PAST_DATA';
export const CALENDAR_MORE_FUTURE_DATA = 'CALENDAR_MORE_FUTURE_DATA';

// Chat actions
export const CHAT_INITIAL_MESSAGES = defineAction('CHAT_INITIAL_MESSAGES', [
  SUCCESS,
  ERROR,
]);
export const CHAT_SOCKET_CONNECT = defineAction('CHAT_SOCKET_CONNECT', [
  SUCCESS,
  ERROR,
]);
export const CHAT_SOCKET_DISCONNECT = defineAction('CHAT_SOCKET_DISCONNECT', [
  SUCCESS,
  ERROR,
]);
export const CHAT_OLD_MESSAGES = defineAction('CHAT_OLD_MESSAGES', [
  SUCCESS,
  ERROR,
]);
export const CHAT_SEND_MESSAGE = defineAction('CHAT_SEND_MESSAGE', [
  SUCCESS,
  ERROR,
]);
export const CHAT_SUBSCRIBE = defineAction('CHAT_SUBSCRIBE', [SUCCESS, ERROR]);
export const CHAT_DISCONNECTED = 'CHAT_DISCONNECTED';
export const CHAT_ADD_MESSAGE = defineAction('CHAT_ADD_MESSAGE', [
  SUCCESS,
  ERROR,
  SENT,
]);
export const CHAT_GET_MESSAGES = defineAction('CHAT_GET_MESSAGES', [
  SUCCESS,
  ERROR,
]);
export const CHAT_ALL_MESSAGES_LOADED = 'CHAT_ALL_MESSAGES_LOADED';

export const CHAT_IMITATION_SEEN = defineAction('CHAT_IMITATION_SEEN', [
  SUCCESS,
  ERROR,
]);

export const SELECTED_CHAT_NUTRIONIST = defineAction(
  'SELECTED_CHAT_NUTRIONIST',
  [SUCCESS, ERROR]
);

// Day Actions
export const DAY_LOAD = defineAction('DAY_LOAD', [SUCCESS, ERROR]);
export const DAY_CLEAR = 'DAY_CLEAR';
// Diary Actions
export const DIARY_ENTRY_CREATE = defineAction('DIARY_ENTRY_CREATE', [
  SUCCESS,
  ERROR,
]);
// Direct Login Actions
export const DIRECT_LOG_IN = 'DIRECT_LOG_IN';
export const DIRECT_LOG_IN_SUCCESS = 'DIRECT_LOG_IN_SUCCESS';
export const DIRECT_LOG_IN_ERROR = 'DIRECT_LOG_IN_ERROR';
// Log Actions
export const LOG_IN = defineAction('LOG_IN', [SUCCESS, ERROR]);
export const LOG_OUT = defineAction('LOG_OUT', [SUCCESS, ERROR]);
export const LOGIN_REDIRECT = 'LOGIN_REDIRECT';
export const FACEBOOK_LOG_IN = 'FACEBOOK_LOG_IN';
export const SHOW_MERGE_MODAL = 'SHOW_MERGE_MODAL';
export const HIDE_MERGE_MODAL = 'HIDE_MERGE_MODAL';
export const FACEBOOK_EMAIL_MERGE = defineAction('FACEBOOK_EMAIL_MERGE', [
  SUCCESS,
  ERROR,
]);
export const FACEBOOK_CONNECT = 'FACEBOOK_CONNECT';
// Meal Actions
export const MEAL_EATEN = defineAction('MEAL_EATEN', [SUCCESS, ERROR]);
export const MEAL_LOAD = defineAction('MEAL_LOAD', [SUCCESS, ERROR]);

export const MEAL_SKIPPED = defineAction('MEAL_SKIPPED', [SUCCESS, ERROR]);
export const MEAL_CLEAR = 'MEAL_CLEAR';
// MealSurvey Actions
export const MEAL_SURVEY_LOAD = defineAction('MEAL_SURVEY_LOAD', [
  SUCCESS,
  ERROR,
]);
export const MEAL_SURVEY_DIET_LIST_LOAD = defineAction(
  'MEAL_SURVEY_DIET_LIST_LOAD',
  [SUCCESS, ERROR]
);
export const MEAL_SURVEY_PRODUCT_LIST_LOAD = defineAction(
  'MEAL_SURVEY_PRODUCT_LIST_LOAD',
  [SUCCESS, ERROR]
);
export const MEAL_SURVEY_TAG_LIST_LOAD = defineAction(
  'MEAL_SURVEY_TAG_LIST_LOAD',
  [SUCCESS, ERROR]
);
export const MEAL_SURVEY_UPDATED = defineAction('MEAL_SURVEY_UPDATED', [
  SUCCESS,
  ERROR,
]);
export const MEAL_SURVEY_PRODUCT_VALIDATE = defineAction(
  'MEAL_SURVEY_PRODUCT_VALIDATE',
  [ERROR]
);
export const MEAL_SURVEY_MEAL_TYPE_VALIDATE = defineAction(
  'MEAL_SURVEY_MEAL_TYPE_VALIDATE',
  [ERROR]
);
// Page Actions
export const PAGE = defineAction('PAGE', [LOADING]);
// Password Actions
export const PASSWORD_RENEW = 'PASSWORD_RENEW';
export const PASSWORD_RENEW_SUCCESS = 'PASSWORD_RENEW_SUCCESS';
export const PASSWORD_RENEW_ERROR = 'PASSWORD_RENEW_ERROR';
export const NEW_PASSWORD_SET = 'NEW_PASSWORD_SET';
export const NEW_PASSWORD_SET_SUCCESS = 'NEW_PASSWORD_SET_SUCCESS';
export const NEW_PASSWORD_SET_ERROR = 'NEW_PASSWORD_SET_ERROR';
// Reset Actions
export const RESET_TOKEN_VALIDATE = 'RESET_TOKEN_VALIDATE';
export const RESET_TOKEN_VALIDATE_SUCCESS = 'RESET_TOKEN_VALIDATE_SUCCESS';
export const RESET_TOKEN_VALIDATE_ERROR = 'RESET_TOKEN_VALIDATE_ERROR';
// SURVEY
export const SURVEY_STEP_NEXT = 'SURVEY_STEP_NEXT';
export const SURVEY_STEP_PREV = 'SURVEY_STEP_PREV';
export const SURVEY_STEP_FIRST = 'SURVEY_STEP_FIRST';
// User Actions
export const USER_CREATE = 'USER_CREATE';
export const USER_LOAD = defineAction('USER_LOAD', [SUCCESS, ERROR]);
export const USER_UPDATE = defineAction('USER_UPDATE', [SUCCESS, ERROR]);
export const USER_SET = 'USER_SET';
export const USER_PASSWORD_UPDATE = defineAction('USER_PASSWORD_UPDATE', [
  SUCCESS,
  ERROR,
]);
export const USER_PASSWORD_NEW = defineAction('USER_PASSWORD_NEW', [
  SUCCESS,
  ERROR,
]);
export const USER_EMAIL_UPDATE = defineAction('USER_EMAIL_UPDATE', [
  SUCCESS,
  ERROR,
]);
export const USER_MEDICAL_AGREEMENT = defineAction('USER_MEDICAL_AGREEMENT', [
  SUCCESS,
  ERROR,
]);
// Unsubscribe Actions
export const UNSUBSCRIBE = defineAction('UNSUBSCRIBE', [SUCCESS, ERROR]);
// Mark as chance Actions
export const MARK_AS_CHANCE = defineAction('MARK_AS_CHANCE', [SUCCESS, ERROR]);
// Shopping list Actions
export const SHOPPING_LIST_LOAD = defineAction('SHOPPING_LIST_LOAD', [
  SUCCESS,
  ERROR,
]);
export const SHOPPING_LIST_CREATE = defineAction('SHOPPING_LIST_CREATE', [
  SUCCESS,
  ERROR,
]);
// Validation Actions
export const VALIDATION_MSG_HAS = 'VALIDATION_MSG_TRUE';
export const VALIDATION_MSG_NONE = 'VALIDATION_MSG_FALSE';
// Workout Actions
export const WORKOUT_LOAD = defineAction('WORKOUT_LOAD', [SUCCESS, ERROR]);
export const WORKOUT_COMPLETE = defineAction('WORKOUT_COMPLETE', [
  SUCCESS,
  ERROR,
]);
export const WORKOUT_DRAFT = defineAction('WORKOUT_DRAFT', [SUCCESS, ERROR]);
export const WORKOUT_DATA_SUBMIT = defineAction('WORKOUT_DATA_SUBMIT', [
  SUCCESS,
  ERROR,
]);
export const WORKOUT_CLEAR = 'WORKOUT_CLEAR';

export const OFFER_LOAD = defineAction('OFFER_LOAD', [SUCCESS, ERROR]);
export const OFFER_CLEAR = defineAction('OFFER_CLEAR', [SUCCESS, ERROR]);

export const INVOICE_LOAD = defineAction('INVOICE_LOAD', [SUCCESS, ERROR]);
// ////
export const RESET_STORE = 'RESET_STORE';
// //
export const TOS_ACCEPT = defineAction('TOS_ACCEPT', [SUCCESS, ERROR]);

export const STORE_CLIENT_ID = 'STORE_CLIENT_ID';

export const SET_LOCALE = defineAction('SET_LOCALE', [SUCCESS, ERROR]);

export const MODAL_ADD = 'MODAL_ADD';
export const MODAL_REMOVE = 'MODAL_REMOVE';

export const FASTING_CYCLE_STORE = 'FASTING_CYCLE_STORE';
export const FASTING_LOAD = 'LOAD_FASTING';
export const FASTING_SUCCESS = 'FASTING_SUCCESS';
export const FASTING_ERROR = 'FASTING_ERROR';
export const START_FASTING_LOAD = 'START_FASTING_LOAD';
export const START_FASTING_SUCCESS = 'START_FASTING_SUCCESS';
export const START_FASTING_ERROR = 'START_FASTING_ERROR';
export const START_EATING_LOAD = 'START_EATING_LOAD';
export const START_EATING_SUCCESS = 'START_EATING_SUCCESS';
export const START_EATING_ERROR = 'START_EATING_ERROR';

interface FastingLoad {
  type: typeof FASTING_LOAD;
}
interface FastingSuccess {
  type: typeof FASTING_SUCCESS;
  fasting: Fasting;
}
interface FastingError {
  type: typeof FASTING_ERROR;
}
interface StartFastingLoad {
  type: typeof START_FASTING_LOAD;
}
interface StartFastingSuccess {
  type: typeof START_FASTING_SUCCESS;
  timer: Timer;
}
interface StartFastingError {
  type: typeof START_FASTING_ERROR;
}
interface StartEatingLoad {
  type: typeof START_EATING_LOAD;
}
interface StartEatingSuccess {
  type: typeof START_EATING_SUCCESS;
  timer: Timer;
}
interface StartEatingError {
  type: typeof START_EATING_ERROR;
}
interface FastingCycleStore {
  type: typeof FASTING_CYCLE_STORE;
  cycle: Cycle;
}

export type FastingActionTypes =
  | FastingLoad
  | FastingSuccess
  | FastingError
  | StartFastingLoad
  | StartFastingSuccess
  | StartFastingError
  | FastingCycleStore
  | StartEatingLoad
  | StartEatingSuccess
  | StartEatingError;

export const UNSUBSCRIBE_COMMONDATA_LOAD = 'UNSUBSCRIBE_COMMONDATA_LOAD';
export const UNSUBSCRIBE_COMMONDATA_SUCCESS = 'UNSUBSCRIBE_COMMONDATA_SUCCESS';
export const UNSUBSCRIBE_COMMONDATA_ERROR = 'UNSUBSCRIBE_COMMONDATA_ERROR';

interface UnsubscribeCommondataLoad {
  type: typeof UNSUBSCRIBE_COMMONDATA_LOAD;
}
interface UnsubscribeCommondataSuccess {
  type: typeof UNSUBSCRIBE_COMMONDATA_SUCCESS;
  unsubscribeCommondata: UnsubscribeCommondata;
}
interface UnsubscribeCommondataError {
  type: typeof UNSUBSCRIBE_COMMONDATA_ERROR;
}

export type UnsubscribeCommondataActionTypes =
  | UnsubscribeCommondataLoad
  | UnsubscribeCommondataSuccess
  | UnsubscribeCommondataError;

export const ACCESS_ITEMS_LOAD = 'ACCESS_ITEMS_LOAD';
export const ACCESS_ITEMS_SUCCESS = 'ACCESS_ITEMS_SUCCESS';
export const ACCESS_ITEMS_ERROR = 'ACCESS_ITEMS_ERROR';

interface AccessItemsLoad {
  type: typeof ACCESS_ITEMS_LOAD;
}
interface AccessItemsSuccess {
  type: typeof ACCESS_ITEMS_SUCCESS;
  accessItems: AccessItems;
}
interface AccessItemsError {
  type: typeof ACCESS_ITEMS_ERROR;
}

export type AccessItemsActionTypes =
  | AccessItemsLoad
  | AccessItemsSuccess
  | AccessItemsError;

export const FREE_ACCESS_TO_USER_LOAD = 'FREE_ACCESS_TO_USER_LOAD';
export const FREE_ACCESS_TO_USER_SUCCESS = 'FREE_ACCESS_TO_USER_SUCCESS';
export const FREE_ACCESS_TO_USER_ERROR = 'FREE_ACCESS_TO_USER_ERROR';

interface FreeAccessToUserLoad {
  type: typeof FREE_ACCESS_TO_USER_LOAD;
}
interface FreeAccessToUserSuccess {
  type: typeof FREE_ACCESS_TO_USER_SUCCESS;
}
interface FreeAccessToUserError {
  type: typeof FREE_ACCESS_TO_USER_ERROR;
}

export type FreeAccessToUserActionTypes =
  | FreeAccessToUserLoad
  | FreeAccessToUserSuccess
  | FreeAccessToUserError;

export const GLOBAL_DATA_LOAD = 'GLOBAL_DATA_LOAD';
export const GLOBAL_DATA_SUCCESS_UNSUBSCRIBE_REASONS =
  'GLOBAL_DATA_SUCCESS_UNSUBSCRIBE_REASONS';
export const GLOBAL_DATA_ERROR = 'GLOBAL_DATA_ERROR';

interface GlobalDataLoad {
  type: typeof GLOBAL_DATA_LOAD;
}
interface GlobalDataSuccessUnsubscribeReasons {
  type: typeof GLOBAL_DATA_SUCCESS_UNSUBSCRIBE_REASONS;
  unsubscribeReasons: UnsubscribeReasons;
}
interface GlobalDataError {
  type: typeof GLOBAL_DATA_ERROR;
}

export type GlobalDataActionTypes =
  | GlobalDataLoad
  | GlobalDataSuccessUnsubscribeReasons
  | GlobalDataError;

//
// Genom Plus survey
//
export const GENOM_PLUS_SURVEY_LOAD = 'GENOM_PLUS_SURVEY_LOAD';
export const GENOM_PLUS_SURVEY_LOAD_SUCCESS = 'GENOM_PLUS_SURVEY_LOAD_SUCCESS';
export const GENOM_PLUS_SURVEY_LOAD_ERROR = 'GENOM_PLUS_SURVEY_LOAD_ERROR';

export interface GenomPlusSurveyLoadActionType {
  type: typeof GENOM_PLUS_SURVEY_LOAD;
}
export interface GenomPlusSurveySuccessActionType {
  type: typeof GENOM_PLUS_SURVEY_LOAD_SUCCESS;
  data: GenomPlusSurveyDataType;
}
export interface GenomPlusSurveyErrorActionType {
  type: typeof GENOM_PLUS_SURVEY_LOAD_ERROR;
  error: Error;
}

//

export const GENOM_PLUS_SURVEY_SAVE = 'GENOM_PLUS_SURVEY_SAVE';
export const GENOM_PLUS_SURVEY_SAVE_SUCCESS = 'GENOM_PLUS_SURVEY_SAVE_SUCCESS';
export const GENOM_PLUS_SURVEY_SAVE_ERROR = 'GENOM_PLUS_SURVEY_SAVE_ERROR';

export interface GenomPlusSurveySaveActionType {
  type: typeof GENOM_PLUS_SURVEY_SAVE;
}
export interface GenomPlusSurveySaveSuccessActionType {
  type: typeof GENOM_PLUS_SURVEY_SAVE_SUCCESS;
  data: GenomPlusSurveyDataType;
}
export interface GenomPlusSurveySaveErrorActionType {
  type: typeof GENOM_PLUS_SURVEY_SAVE_ERROR;
  error: Error;
}

export type GenomPlusSurveyActionTypes =
  | GenomPlusSurveyLoadActionType
  | GenomPlusSurveySuccessActionType
  | GenomPlusSurveyErrorActionType
  | GenomPlusSurveySaveActionType
  | GenomPlusSurveySaveSuccessActionType
  | GenomPlusSurveySaveErrorActionType;

export const SET_WORKOUTS_STATE = 'SET_WORKOUTS_STATE';

export interface WorkoutsStateActionType {
  type: typeof SET_WORKOUTS_STATE;
  payload: InternalWorkoutState;
}

export type WorkoutsActionTypes = WorkoutsStateActionType;
