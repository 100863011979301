import { User } from 'types/user';
import { AttachedOffers, Offer, OfferPrice } from 'types/offer';
import { AllowedCountries } from 'types/physicalProducts';

import settings from './settings';
import { getStandardOfferPrice, isOfferSubscription } from './offerHelper';

export type ReceiptType = 'subscription' | 'single';

export type ReceiptValues = {
  boxCount: number;
  singleAmount: number;
  barPrice: string;
  total: number;
  currency: string;
};

export type ReceiptItems = {
  subscription: ReceiptValues;
  single: ReceiptValues;
};

export const KETO_CHOCOLATE_BARS_LOCALES: string[] = [
  'fr',
  'cs',
  'da',
  'ro',
  'sv',
  'pl',
  'it',
  'hr',
  'bg',
  'fi',
  'sk',
  'el',
  'de',
  'et',
  'pt',
  'lt',
  'hu',
  'nl',
];

export const KETO_CHOCOLATE_BARS_ALLOWED_COUNTRIES: AllowedCountries = [
  {
    value: 'DK',
    emoji: '🇩🇰',
    name: 'Denmark',
  },
  {
    value: 'FI',
    emoji: '🇫🇮',
    name: 'Finland',
  },
  {
    value: 'FR',
    emoji: '🇫🇷',
    name: 'France',
  },
  {
    value: 'DE',
    emoji: '🇩🇪',
    name: 'Germany',
  },
  {
    value: 'GR',
    emoji: '🇬🇷',
    name: 'Greece',
  },
  {
    value: 'IT',
    emoji: '🇮🇹',
    name: 'Italy',
  },
  {
    value: 'NL',
    emoji: '🇳🇱',
    name: 'Netherlands',
  },
  {
    value: 'PT',
    emoji: '🇵🇹',
    name: 'Portugal',
  },
  {
    value: 'CZ',
    emoji: '🇨🇿',
    name: 'Chech',
  },
  {
    value: 'RO',
    emoji: '🇷🇴',
    name: 'Romania',
  },
  {
    value: 'SE',
    emoji: '🇸🇪',
    name: 'Sweden',
  },
  {
    value: 'PL',
    emoji: '🇵🇱',
    name: 'Poland',
  },
  {
    value: 'HR',
    emoji: '🇭🇷',
    name: 'Croatia',
  },
  {
    value: 'BG',
    emoji: '🇧🇬',
    name: 'Bulgaria',
  },
  {
    value: 'SK',
    emoji: '🇸🇰',
    name: 'Slovakia',
  },
  {
    value: 'EE',
    emoji: '🇪🇪',
    name: 'Estonia',
  },
  {
    value: 'LT',
    emoji: '🇱🇹',
    name: 'Lithuania',
  },
  {
    value: 'LV',
    emoji: '🇱🇻',
    name: 'Latvia',
  },
  {
    value: 'HU',
    emoji: '🇭🇺',
    name: 'Hungary',
  },
  {
    value: 'AT',
    emoji: '🇦🇹',
    name: 'Austria',
  },
  {
    value: 'BE',
    emoji: '🇧🇪',
    name: 'Belgium',
  },
  {
    value: 'CY',
    emoji: '🇨🇾',
    name: 'Republic of Cyprus',
  },
  {
    value: 'IE',
    emoji: '🇮🇪',
    name: 'Ireland',
  },
  {
    value: 'LU',
    emoji: '🇱🇺',
    name: 'Luxembourg',
  },
  {
    value: 'MT',
    emoji: '🇲🇹',
    name: 'Malta',
  },
  {
    value: 'SI',
    emoji: '🇸🇮',
    name: 'Slovenia',
  },
  {
    value: 'ES',
    emoji: '🇪🇸',
    name: 'Spain',
  },
];

export const getIntialCountry = (user: User): string => {
  const userCountry = user.country;
  const allowedCountries = KETO_CHOCOLATE_BARS_ALLOWED_COUNTRIES.map(
    (country) => country.value
  );

  return allowedCountries.includes(userCountry) ? userCountry : '';
};

export const sortCountriesAlphabetically = (
  countries: AllowedCountries
): AllowedCountries => countries.sort((a, b) => a.name.localeCompare(b.name));

export const isKetoChocolateBarsEnabled = (): boolean =>
  settings.KET_CHOCOLATE_BARS_ENABLED === 'true';

export const isKetoChocolateBarsAllowed = (user: User): boolean => {
  if (!isKetoChocolateBarsEnabled()) return false;
  if (!KETO_CHOCOLATE_BARS_LOCALES.includes(user.locale)) return false;

  return true;
};

export enum KETO_CHOCOLATE_OFFER_ACTIVITY_SOURCE {
  announcement1 = 'announcement_1',
  announcement2 = 'announcement_2',
  announcement3 = 'announcement_3',
  announcement4 = 'announcement_4',
  announcement5 = 'announcement_5',
  fullOffer = 'full_offer',
  addressInput = 'address_input',
  outOfStockModal = 'out_of_stock_modal',
  contextualOffer = 'contextual_offer',
}

export enum KETO_CHOCOLATE_OFFER_ACTIVITY_EVENT {
  open = 'open',
  positiveAnswer = 'positive_answer',
  negativeAnswer = 'negative_answer',
  declineOffer = 'decline_offer',
  closeModal = 'close_modal',
  commitPurchase = 'commit_purchase',
  swipeTestimonials = 'swipe_testimonials',
  subscribeOfferBuy = 'subscribe_offer_buy',
  oneTimeOrderBuy = 'one_time_order_buy',
  toggleSubscribe = 'toggle_subscribe',
  toggleSingle = 'toggle_buy_once',
  close = 'close',
  continue = 'continue',
}

export const isOfferRegistration = (offer: Offer): boolean =>
  offer.offer_type.includes('registration');

export const getInitialSubscriptionOffer = (attachedOffers: AttachedOffers) =>
  attachedOffers.find((offer) => {
    const standardOfferPrice = getStandardOfferPrice(offer);
    if (!isOfferSubscription(standardOfferPrice as OfferPrice)) {
      return null;
    }

    return offer;
  });

export const getInitialSingleOffer = (attachedOffers: AttachedOffers) =>
  attachedOffers.find((offer) => {
    const standardOfferPrice = getStandardOfferPrice(offer);
    if (isOfferSubscription(standardOfferPrice as OfferPrice)) {
      return null;
    }

    return offer;
  });

export const generateRandomNumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const TRANSLATIONS_KEYS: { [key: string]: string } = {
  KetoChocolateBarsV2: 'ketochocolatebarsv2',
  KetoChocolateBarsV3: 'ketochocolatebarsv3',
};

export const getTranslationKey = (
  templateName: 'KetoChocolateBarsV2' | 'KetoChocolateBarsV3'
): string => TRANSLATIONS_KEYS[templateName];

// Temporal solution, otherwise can't decide in FE
export const getProgramPageTemplateName = (
  user: User
): 'KetoChocolateBars' | 'KetoChocolateBarsV2' | 'KetoChocolateBarsV3' => {
  const secondTemplateCategories = [1, 3, 4, 5, 6];
  const thirdTemplateCategories = [2, 7, 8, 9, 10];

  if (secondTemplateCategories.includes(user.category)) {
    return 'KetoChocolateBarsV2';
  }
  if (thirdTemplateCategories.includes(user.category)) {
    return 'KetoChocolateBarsV3';
  }

  // TODO: Remove everything about default first template - translations/assets/etc. - after tests
  return 'KetoChocolateBars';
};

export const getProgramPageTranslationKey = (
  template: 'KetoChocolateBars' | 'KetoChocolateBarsV2' | 'KetoChocolateBarsV3'
): string => {
  const translationKeys = {
    KetoChocolateBars: 'ketochocolatebars',
    KetoChocolateBarsV2: 'ketochocolatebarsv2',
    KetoChocolateBarsV3: 'ketochocolatebarsv3',
  };

  return translationKeys[template];
};
