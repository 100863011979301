export type Benefit =
  | 'antioxidants'
  | 'blood'
  | 'bones'
  | 'brain'
  | 'stomach'
  | 'energy'
  | 'eyes'
  | 'heart'
  | 'immunity'
  | 'age'
  | 'metabolism'
  | 'muscles'
  | 'satiety'
  | 'skin'
  | 'sleep'
  | 'teeth'
  | 'feel';

export type Day = {
  isLoading: boolean;
  id: string;
  type: 'days';
  date: string;
  diary_entry?: DiaryEntry;
  day_meals: MealItem[];
  day_workouts?: Workout[];
};

export type DiaryEntry = {
  id: number;
  type: 'diary_entries';
  day_id: number;
  energy_percent: number;
  weight?: number;
};

export type MealState = 'eaten' | 'draft';

export type MealItem = {
  id: number;
  type: 'day_meals';
  code?: string;
  cooking_time?: number;
  image_url?: string;
  title?: string;
  benefits?: Benefit[];
  meal_type: MealType;
  state: MealState;
};

export type WorkoutState = 'completed' | 'draft';

export type Workout = {
  id: number;
  type: 'day_workouts';
  code: string;
  title: string;
  workout_time: number;
  description: string;
  state: WorkoutState;
  image_url: string;
};

export enum MealType {
  breakfast = 'breakfast',
  lunch = 'lunch',
  dinner = 'dinner',
  snack = 'snack',
  trick = 'trick',
}

export enum MealIconSize {
  small = 'small',
  large = 'large',
}

type ObjectOfStringKeyValue = {
  [key: string]: string;
};

export const KETO_HELPDESK_LINKS: ObjectOfStringKeyValue = {
  hr: 'https://helpdesk.certifiedketodiet.com/ckd_hr_app',
  sk: 'https://helpdesk.certifiedketodiet.com/ckd_sk_app',
  bg: 'https://helpdesk.certifiedketodiet.com/ckd_bg_app',
  cs: 'https://helpdesk.certifiedketodiet.com/ckd_cs_app',
  fr: 'https://helpdesk.certifiedketodiet.com/ckd_fr_app',
  de: 'https://helpdesk.certifiedketodiet.com/ckd_de_app',
  el: 'https://helpdesk.certifiedketodiet.com/ckd_el_app',
  hu: 'https://helpdesk.certifiedketodiet.com/ckd_hu_app',
  pl: 'https://helpdesk.certifiedketodiet.com/ckd_pl_app',
  pt: 'https://helpdesk.certifiedketodiet.com/ckd_pt_app',
  ro: 'https://helpdesk.certifiedketodiet.com/ckd_ro_app',
  ru: 'https://helpdesk.certifiedketodiet.com/ckd_ru_app',
  es: 'https://helpdesk.certifiedketodiet.com/ckd_es_app',
  et: 'https://helpdesk.certifiedketodiet.com/ckd_et_app',
  lv: 'https://helpdesk.certifiedketodiet.com/ckd_en_app',
  lt: 'https://helpdesk.certifiedketodiet.com/ckd_lt_app',
  en: 'https://helpdesk.certifiedketodiet.com/ckd_en_app',
  da: 'https://helpdesk.certifiedketodiet.com/ckd_da_app',
  nl: 'https://helpdesk.certifiedketodiet.com/ckd_nl_app',
  it: 'https://helpdesk.certifiedketodiet.com/ckd_it_app',
  sr: 'https://helpdesk.certifiedketodiet.com/ckd_sr_app',
  bs: 'https://helpdesk.certifiedketodiet.com/ckd_bs_app',
  ka: 'https://helpdesk.certifiedketodiet.com/ckd_ka_app',
  uk: 'https://helpdesk.certifiedketodiet.com/ckd_uk_app',
  no: 'https://helpdesk.certifiedketodiet.com/ckd_no_app',
  sv: 'https://helpdesk.certifiedketodiet.com/ckd_sv_app',
  fi: 'https://helpdesk.certifiedketodiet.com/ckd_fi_app',
  he: 'https://helpdesk.certifiedketodiet.com/ckd_he_app',
};

export const KETOGO_HELPDESK_LINKS: ObjectOfStringKeyValue = {
  hr: 'https://helpdesk.ketogo.app/kg_hr_app',
  sk: 'https://helpdesk.ketogo.app/kg_sk_app',
  bg: 'https://helpdesk.ketogo.app/kg_bg_app',
  cs: 'https://helpdesk.ketogo.app/kg_cs_app',
  fr: 'https://helpdesk.ketogo.app/kg_fr_app',
  de: 'https://helpdesk.ketogo.app/kg_de_app',
  el: 'https://helpdesk.ketogo.app/kg_el_app',
  hu: 'https://helpdesk.ketogo.app/kg_hu_app',
  pl: 'https://helpdesk.ketogo.app/kg_pl_app',
  pt: 'https://helpdesk.ketogo.app/kg_pt_app',
  ro: 'https://helpdesk.ketogo.app/kg_ro_app',
  ru: 'https://helpdesk.ketogo.app/kg_ru_app',
  es: 'https://helpdesk.ketogo.app/kg_es_app',
  et: 'https://helpdesk.ketogo.app/kg_et_app',
  lv: 'https://helpdesk.ketogo.app/kg_en_app',
  lt: 'https://helpdesk.ketogo.app/kg_lt_app',
  en: 'https://helpdesk.ketogo.app/kg_en_app',
  nl: 'https://helpdesk.ketogo.app/kg_nl_app',
  da: 'https://helpdesk.ketogo.app/kg_da_app',
  it: 'https://helpdesk.ketogo.app/kg_it_app',
  sr: 'https://helpdesk.ketogo.app/kg_sr_app',
  bs: 'https://helpdesk.ketogo.app/kg_bs_app',
  no: 'https://helpdesk.ketogo.app/kg_no_app',
  ka: 'https://helpdesk.ketogo.app/kg_ka_app',
  fi: 'https://helpdesk.ketogo.app/kg_fi_app',
  sv: 'https://helpdesk.ketogo.app/kg_sv_app',
};

export const DIET_HELPDESK_LINKS: ObjectOfStringKeyValue = {
  en: 'https://helpdesk.stockholmdiet.com/sd_en_app',
  cs: 'https://helpdesk.stockholmdiet.com/sd_cs_app',
  fr: 'https://helpdesk.stockholmdiet.com/sd_fr_app',
  de: 'https://helpdesk.stockholmdiet.com/sd_de_app',
  hr: 'https://helpdesk.stockholmdiet.com/sd_hr_app',
  ro: 'https://helpdesk.stockholmdiet.com/sd_ro_app',
  ru: 'https://helpdesk.stockholmdiet.com/sd_ru_app',
  es: 'https://helpdesk.stockholmdiet.com/sd_es_app',
  et: 'https://helpdesk.stockholmdiet.com/sd_et_app',
  lv: 'https://helpdesk.stockholmdiet.com/sd_en_app',
  lt: 'https://helpdesk.stockholmdiet.com/sd_lt_app',
  sk: 'https://helpdesk.stockholmdiet.com/sd_sk_app',
};

export const CF_HELPDESK_LINKS: ObjectOfStringKeyValue = {
  en: 'https://helpdesk.certifiedfasting.com/cf_en_app',
  es: 'https://helpdesk.certifiedfasting.com/cf_es_app',
  pt: 'https://helpdesk.certifiedfasting.com/cf_pt_app',
  de: 'https://helpdesk.certifiedfasting.com/cf_de_app',
  ro: 'https://helpdesk.certifiedfasting.com/cf_ro_app',
  hu: 'https://helpdesk.certifiedfasting.com/cf_hu_app',
  cs: 'https://helpdesk.certifiedfasting.com/cf_cs_app',
  uk: 'https://helpdesk.certifiedfasting.com/cf_uk_app',
  no: 'https://helpdesk.certifiedfasting.com/cf_no_app',
  el: 'https://helpdesk.certifiedfasting.com/cf_el_app',
  bg: 'https://helpdesk.certifiedfasting.com/cf_bg_app',
  fi: 'https://helpdesk.certifiedfasting.com/cf_fi_app',
  pl: 'https://helpdesk.certifiedfasting.com/cf_pl_app',
  sk: 'https://helpdesk.certifiedfasting.com/cf_sk_app',
  fr: 'https://helpdesk.certifiedfasting.com/cf_fr_app',
  he: 'https://helpdesk.certifiedfasting.com/cf_he_app',
};

export const HG_HELPDESK_LINKS: ObjectOfStringKeyValue = {
  en: 'http://helpdesk.healthgenom.com/hg_en_app',
  es: 'http://helpdesk.healthgenom.com/hg_es_app',
  it: 'http://helpdesk.healthgenom.com/hg_it_app',
};

export const MF_HELPDESK_LINKS: ObjectOfStringKeyValue = {
  en: 'https://monacofit.zendesk.com/hc/en-001',
};

type ObjectOfStringKeyValueNested = {
  [key: string]: { [key: string]: string };
};

export const HELPDESK_LINKS_FOR_PRODUCT: ObjectOfStringKeyValueNested = {
  keto: KETO_HELPDESK_LINKS,
  ketogo: KETOGO_HELPDESK_LINKS,
  fasting: CF_HELPDESK_LINKS,
  diet: DIET_HELPDESK_LINKS,
  healthgenom: HG_HELPDESK_LINKS,
  monacofit: MF_HELPDESK_LINKS,
};

export type UTMS = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};
export const KETO_CANCELLATION_FEE_LINKS = {
  en: 'https://helpdesk.certifiedketodiet.com/en_cancellation_fee',
  hr: 'https://helpdesk.certifiedketodiet.com/hr_cancellation_fee',
  sk: 'https://helpdesk.certifiedketodiet.com/sk_cancellation_fee',
  bg: 'https://helpdesk.certifiedketodiet.com/bg_cancellation_fee',
  cs: 'https://helpdesk.certifiedketodiet.com/cs_cancellation_fee',
  fr: 'https://helpdesk.certifiedketodiet.com/fr_cancellation_fee',
  de: 'https://helpdesk.certifiedketodiet.com/de_cancellation_fee',
  el: 'https://helpdesk.certifiedketodiet.com/el_cancellation_fee',
  hu: 'https://helpdesk.certifiedketodiet.com/hu_cancellation_fee',
  pl: 'https://helpdesk.certifiedketodiet.com/pl_cancellation_fee',
  pt: 'https://helpdesk.certifiedketodiet.com/pt_cancellation_fee',
  ro: 'https://helpdesk.certifiedketodiet.com/ro_cancellation_fee',
  ru: 'https://helpdesk.certifiedketodiet.com/ru_cancellation_fee',
  es: 'https://helpdesk.certifiedketodiet.com/es_cancellation_fee',
  et: 'https://helpdesk.certifiedketodiet.com/et_cancellation_fee',
  lv: 'https://helpdesk.certifiedketodiet.com/lv_cancellation_fee',
  lt: 'https://helpdesk.certifiedketodiet.com/lt_cancellation_fee',
  da: 'https://helpdesk.certifiedketodiet.com/da_cancellation_fee',
  nl: 'https://helpdesk.certifiedketodiet.com/nl_cancellation_fee',
  it: 'https://helpdesk.certifiedketodiet.com/it_cancellation_fee',
  sr: 'https://helpdesk.certifiedketodiet.com/sr_cancellation_fee',
  bs: 'https://helpdesk.certifiedketodiet.com/bs_cancellation_fee',
  ka: 'https://helpdesk.certifiedketodiet.com/ka_cancellation_fee',
  uk: 'https://helpdesk.certifiedketodiet.com/uk_cancellation_fee',
  no: 'https://helpdesk.certifiedketodiet.com/no_cancellation_fee',
  sv: 'https://helpdesk.certifiedketodiet.com/sv_cancellation_fee',
  fi: 'https://helpdesk.certifiedketodiet.com/fi_cancellation_fee',
  he: 'https://helpdesk.certifiedketodiet.com/he_cancellation_fee',
};

export const HELPDESK_CANCELLATION_FEE_LINKS: ObjectOfStringKeyValueNested = {
  keto: KETO_CANCELLATION_FEE_LINKS,
};
