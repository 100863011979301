import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useTheme from 'helpers/themeHook';
import { getMonthDate } from 'helpers/timeHelper';
import { getFasting } from 'actions/fastingActions';
import { isPrimaryTheme } from 'helpers/themeHelper';
import { getCurrentDateFnsLocale } from 'helpers/localeHelper';
import { isFastingBlockAvailable } from 'helpers/fastingHelper';
import { formatISODate, isFutureISO, isTodayISO } from 'helpers/dateHelper';

import { getUserDayData } from 'actions/programActions';

import Events from './Events';
import getDayNameDateFormat from './getDayNameDateFormat';

import base from './CalendarItem.module.scss';
import diet from './CalendarItem_diet.module.scss';
import keto from './CalendarItem_keto.module.scss';
import ketogo from './CalendarItem_ketogo.module.scss';
import fasting from './CalendarItem_fasting.module.scss';
import healthgenom from './CalendarItem_healthgenom.module.scss';

const CalendarItem = ({ day, day: { date }, id, isSelected, selectedRef }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.rootReducer.user);
  const itemRef = useRef();
  const [s] = useTheme({ base, diet, keto, ketogo, fasting, healthgenom });

  let className = 'done';
  if (isFutureISO(date)) {
    className = 'future';
  } else if (isTodayISO(date)) {
    className = 'today';
  }

  const format = getDayNameDateFormat();

  const dateName = isPrimaryTheme()
    ? getMonthDate(date)
    : formatISODate(date, 'dd');

  const handleClick = () => {
    dispatch(getUserDayData(id));

    if (isTodayISO(date) && isFastingBlockAvailable(user)) {
      dispatch(getFasting());
    }
  };

  return (
    <li
      tabIndex={0}
      className={`${className} ${s[className]} ${
        isSelected ? s.isSelected : ''
      }`}
      id={`day_${id}`}
      onClick={() => handleClick()}
      onKeyPress={() => handleClick()}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      ref={isSelected ? selectedRef : itemRef}
    >
      <h5 className={s.dayName}>
        {formatISODate(date, format, getCurrentDateFnsLocale())}
      </h5>
      <p className={s.date}>{dateName}</p>
      <Events day={day} />
    </li>
  );
};

export default CalendarItem;
