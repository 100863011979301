import qs from 'qs';
import i18n from 'i18n';
import { addError } from 'redux-flash-messages';

import { User } from 'types/user';
import { Paymodes } from 'types/offer';
import { PaymentUrl, ItemType, Invoice, Frequency } from '../types/payment';

import { userHasFinishedRegisteration } from './userHelper';
import { apiHost } from './requestHelpers';
import { api } from './api';
import settings from './settings';

export const paymentNeedsCardSaveModal = (invoice: any): boolean => {
  if (invoice.item_type === ItemType.pro && !isAnnualProPayment(invoice)) {
    return false;
  }
  if (!invoice.card) {
    return false;
  }
  if (invoice.card.tos_accepted_at) {
    return false;
  }

  return true;
};

export const isPaymentSuccessful = (invoice: any): boolean => {
  const paidStatuses: string[] = ['paid', 'charge_delayed'];
  return paidStatuses.includes(invoice.status);
};

export const getSuccessfulPaymentDestinationPath = (
  invoice: any,
  user: User
): string => {
  if (invoice.item_type === 'premium_upsell') {
    // Contextual offer redirect
    if (invoice.custom_data?.purchase_source) {
      return invoice.custom_data.purchase_source;
    }
    if (userHasFinishedRegisteration(user)) {
      return '/premium';
    }
  }
  if (
    invoice.item_type === 'shopping_list' &&
    invoice.custom_data.purchase_source
  ) {
    return invoice.custom_data.purchase_source;
  }
  if (invoice.item_type === 'fat_burner') {
    return '/fat-burner-extract-address/success';
  }
  if (invoice.item_type === 'acv_gummies') {
    return '/apple-cider-vinegar-gummies-address/success';
  }
  if (invoice.item_type === 'collagen') {
    return '/collagen-address/success';
  }
  if (invoice.item_type === 'genom') {
    return '/genomplus/success';
  }

  return '/';
};

export const cardApi = async (action: string, cardId: number) => {
  try {
    await api(`api/v1/users/card/${cardId}/tos/${action}`, {
      method: 'POST',
    });
  } catch (e) {
    addError({ text: i18n.t('error.500_error') });
  }
};

export const getPaymentUrl = ({
  paymode,
  userId,
  offerId,
  queryParams,
}: PaymentUrl) => {
  const endpointUrl = `${apiHost()}/pay/${paymode}/${userId}/${offerId}`;
  const params = qs.stringify(queryParams);

  return `${endpointUrl}?${params}`;
};

export const isAnnualProPayment = (invoice: Invoice) =>
  invoice.purchase_item?.frequency === Frequency.year &&
  invoice.purchase_item?.item.type === ItemType.pro;

export const isInvoicePaymodePrimer = (invoice: Invoice): boolean =>
  invoice.paymode_name === Paymodes.primer;

export const isInvoicePaymodeStripe = (invoice: Invoice): boolean =>
  invoice.paymode_name === Paymodes.stripe;

export const isChatInvoice = (invoice: Invoice) => invoice.item_type === 'chat';

export const isCancellationFeeInvoice = (invoice: Invoice) =>
  invoice.item_type === ItemType.earlyCancellationFee;

export const isGenomRelatedInvoice = (invoice: Invoice) => {
  const genomAccesses = [
    'genom',
    'genom_blood_test',
    'genom_saliva_test',
    'genom_micro_test',
  ];

  return genomAccesses.includes(invoice.item_type);
};

const isExpressPlanInvoice = (invoice: Invoice) =>
  invoice.item_type === ItemType.expressPlanDelivery;

const isWorkoutsPlanInvoice = (invoice: Invoice) =>
  invoice.item_type === ItemType.workouts;

export const isDefaultSuccessScreenItem = (invoice: Invoice) => {
  if (isGenomRelatedInvoice(invoice)) return true;
  if (isExpressPlanInvoice(invoice)) return true;
  if (isWorkoutsPlanInvoice(invoice)) return true;

  return false;
};

export const isPrimerCardSavedForUser = (user: User): boolean => {
  const { cards } = user;

  if (!cards) {
    return false;
  }

  const savedCardPaymode = cards.find(
    (card) => card.paymode.name === Paymodes.primer
  );

  if (!savedCardPaymode) {
    return false;
  }

  return true;
};

export const getPrimerPaymentId = (): number => {
  if (typeof settings.PRIMER_PAYMENT_METHOD_ID === 'string') {
    return parseInt(settings.PRIMER_PAYMENT_METHOD_ID, 10);
  }

  return settings.PRIMER_PAYMENT_METHOD_ID;
};

export const getDefaultSuccessImageName = (invoice: Invoice) => {
  const imageNames = {
    genom: 'genom',
    expressPlanDelivery: 'express_plan_delivery',
    workouts: 'workouts',
  };

  if (isGenomRelatedInvoice(invoice)) return imageNames.genom;
  if (isExpressPlanInvoice(invoice)) return imageNames.expressPlanDelivery;
  if (isWorkoutsPlanInvoice(invoice)) return imageNames.workouts;

  return '';
};

export const getDefaultSuccessDestinationPath = (invoice: Invoice) => {
  if (invoice.item_type === ItemType.genom) return '/genomplus/success';
  if (isExpressPlanInvoice(invoice)) return '/program?express-plan-done';

  return '/';
};

export const isDefaultTextCopyNeeded = (invoice: Invoice) => {
  const isGenomInvoice = isGenomRelatedInvoice(invoice);

  if (isGenomInvoice) return true;
  if (isExpressPlanInvoice(invoice)) return true;
  if (isWorkoutsPlanInvoice(invoice)) return true;

  return false;
};
