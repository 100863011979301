import { combineReducers } from 'redux';
import session from 'reducers/sessionReducer';
import chat from 'reducers/chatReducer';
import user from 'reducers/userReducer';
import day from 'reducers/dayReducer';
import meal from 'reducers/mealReducer';
import workout from 'reducers/workoutReducer';
import isLoading from 'reducers/isLoadingReducer';
import reachedLastDay from 'reducers/reachedLastDayReducer';
import reachedFirstDay from 'reducers/reachedFirstDayReducer';
import diets from 'reducers/dietsReducer';
import productList from 'reducers/productListReducer';
import mealTags from 'reducers/mealTagsReducer';
import calendar from 'reducers/calendarReducer';
import { flashMessage } from 'redux-flash-messages';
import validationMessage from 'reducers/validationMessageReducer';
import surveyStep from 'reducers/surveyStepReducer';
import offer from 'reducers/offerReducer';
import invoice from 'reducers/invoiceReducer';
import mealSurvey from 'reducers/mealSurveyReducer';
import shoppingList from 'reducers/shoppingListReducer';
import messages from 'reducers/messagesReducer';
import allMessagesLoaded from 'reducers/allMessagesLoadedReducer';
import isConnectedToCable from 'reducers/isConnectedToCableReducer';
import currentLocale from 'reducers/localeReducer';
import modals from 'reducers/modalsReducer';
import fasting from 'reducers/fastingReducer';
import unsubscribeCommondata from 'reducers/unsubscribeReducer';
import accessItems from 'reducers/accessReducer';
import freeAccess from 'reducers/freeAccessReducer';
import globalData from 'reducers/globalDataReducer';
import genomPlusSurvey from 'reducers/genomPlusSurveyReducer';
import workouts from 'reducers/workoutsReducer';

const rootReducer = combineReducers({
  user,
  day,
  meal,
  workout,
  session,
  chat,
  isLoading,
  reachedLastDay,
  reachedFirstDay,
  diets,
  productList,
  mealTags,
  calendar,
  flashMessage,
  validationMessage,
  surveyStep,
  offer,
  invoice,
  mealSurvey,
  shoppingList,
  messages,
  allMessagesLoaded,
  isConnectedToCable,
  currentLocale,
  modals,
  fasting,
  unsubscribeCommondata,
  accessItems,
  freeAccess,
  globalData,
  genomPlusSurvey,
  workouts,
});

export default rootReducer;
