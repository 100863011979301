const settings = {
  BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG_API_KEY ?? '',
  GOOGLE_PLACES_API_KEY: process.env.REACT_APP_GOOGLE_PLACES_API_KEY ?? '',
  HEAP_APP_ID: process.env.REACT_APP_HEAP_ID ?? '',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN ?? '',
  FACEBOOK_LOGIN: process.env.REACT_APP_FACEBOOK_LOGIN === 'true' ?? false,
  FACEBOOK_CLIENT_ID: process.env.REACT_APP_FACEBOOK_CLIENT_ID ?? '',
  FACEBOOK_REDIRECT_URI: process.env.REACT_APP_FACEBOOK_REDIRECT_URI ?? '',
  GTM_ID: process.env.REACT_APP_GTM_ID ?? '',
  GTM_AUTH: process.env.REACT_APP_GTM_AUTH ?? '',
  GTM_PREVIEW: process.env.REACT_APP_GTM_PREVIEW ?? '',
  API_HOST: process.env.REACT_APP_API_HOST ?? '',
  THEME: process.env.REACT_APP_THEME ?? '',
  HAS_MATERIAL_UI: process.env.REACT_APP_HAS_MATERIAL_UI === 'true' ?? false,
  HAS_LANGUAGE_DROPDOWN_EMOJI:
    process.env.REACT_APP_HAS_LANGUAGE_DROPDOWN_EMOJI === 'true' ?? false,
  SHOW_TOS_BLOCK_IN_PROFILE:
    process.env.REACT_APP_SHOW_TOS_BLOCK_IN_PROFILE === 'true' ?? false,
  HAS_TESTIMONIAL: process.env.REACT_APP_HAS_TESTIMONIAL === 'true' ?? false,
  SHOW_DIARY_CARD: process.env.REACT_APP_SHOW_DIARY_CARD === 'true' ?? false,
  HAS_DIARY_UNSKIPABLE:
    process.env.REACT_APP_HAS_DIARY_UNSKIPABLE === 'true' ?? false,
  SHOW_PLAN_IN_PROFILE:
    process.env.REACT_APP_SHOW_PLAN_IN_PROFILE === 'true' ?? false,
  SHOW_PLAN_UPDATE: process.env.REACT_APP_SHOW_PLAN_UPDATE === 'true' ?? false,
  SHOW_MEAL_CHECKMARK:
    process.env.REACT_APP_SHOW_MEAL_CHECKMARK === 'true' ?? false,
  CAN_CHANGE_GOAL: process.env.REACT_APP_CAN_CHANGE_GOAL === 'true' ?? false,
  SHOW_STATISTICS: process.env.REACT_APP_SHOW_STATISTICS === 'true' ?? false,
  SHOW_PREMIUM: process.env.REACT_APP_SHOW_PREMIUM === 'true' ?? false,
  SHOW_PREMIUM_SUBSCRIPTION:
    process.env.REACT_APP_SHOW_PREMIUM_SUBSCRIPTION === 'true' ?? false,
  SHOW_UNSUBSCRIBEV6:
    process.env.REACT_APP_SHOW_UNSUBSCRIBEV6 === 'true' ?? false,
  SHOW_UNSUBSCRIBEV7:
    process.env.REACT_APP_SHOW_UNSUBSCRIBEV7 === 'true' ?? false,
  SHOW_FAT_BURNER: process.env.REACT_APP_SHOW_FAT_BURNER === 'true' ?? false,
  SHOW_ANNUAL_PRO: process.env.REACT_APP_SHOW_ANNUAL_PRO === 'true' ?? false,
  SHOW_FASTING: process.env.REACT_APP_SHOW_FASTING === 'true' ?? false,
  SHOW_MEALSURVEY_MEAL_STEP:
    process.env.REACT_APP_SHOW_MEALSURVEY_MEAL_STEP === 'true' ?? false,
  SHOW_COLLAGEN: process.env.REACT_APP_SHOW_COLLAGEN === 'true' ?? false,
  PAUSE_COLLAGEN: process.env.REACT_APP_PAUSE_COLLAGEN === 'true' ?? false,
  PRIMER_PAYMENT_METHOD_ID:
    process.env.REACT_APP_PRIMER_PAYMENT_METHOD_ID ?? 15,
  PAYWALL_ENABLED: process.env.REACT_APP_PAYWALL_ENABLED === 'true' ?? false,
  CHAT_PAYWALL_ENABLED:
    process.env.REACT_APP_CHAT_PAYWALL_ENABLED === 'true' ?? false,
  UNSUBSCRIBEV8_ALLOWED_CATEGORIES:
    process.env.REACT_APP_UNSUBSCRIBEV8_ALLOWED_CATEGORIES ?? '',
  SHOW_ACV_GUMMIES: process.env.REACT_APP_SHOW_ACV_GUMMIES === 'true' ?? false,
  UNSUBSCRIBEV8_FREE_OFFER_CATEGORIES:
    process.env.REACT_APP_UNSUBSCRIBEV8_FREE_OFFER_CATEGORIES ?? '',
  GENOM_PLUS_CATEGORIES: process.env.REACT_APP_GENOM_PLUS_CATEGORIES ?? '',
  CHAT_AS_SUBSCRIPTION_CATEGORIES:
    process.env.REACT_APP_CHAT_AS_SUBSCRIPTION_CATEGORIES ?? '',
  EXPRESS_PLAN_ENABLED:
    process.env.REACT_APP_EXPRESS_PLAN_ENABLED === 'true' ?? false,
  WORKOUTS_CATEGORIES: process.env.REACT_APP_WORKOUTS_CATEGORIES ?? '',
  KET_CHOCOLATE_BARS_ENABLED:
    process.env.REACT_APP_KET_CHOCOLATE_BARS_ENABLED ?? '',
  PREMIUM_LASTCHANCE_ENABLED:
    process.env.REACT_APP_PREMIUM_LASTCHANCE_ENABLED === 'true' ?? false,
};

export default settings;
