import { Subscription } from './subscription';

export type UserStatistics = {
  lose: number;
  lose_in_lbs: number;
  lost: number;
  lost_in_lbs: number;
  goal: number;
  goal_in_lbs: number;
  current: number;
  current_in_lbs: number;
  unit_system: UnitSystem;
};

export enum Gender {
  male = 'male',
  female = 'female',
}

export enum UserState {
  registered = 'registered',
  draft = 'draft',
}

export enum UnitSystem {
  si = 'si',
  imperial = 'imperial',
}

export enum ProgramUpdateReason {
  weightChange,
  notMaintenance,
  goalReached,
}

export type ProgramUpdate = {
  available: boolean;
  reason: ProgramUpdateReason | null;
};

export type Ticket = {
  id: number;
  item: {
    type: string;
  };
  paid_from: string;
  paid_till: string;
  type: string;
};

export type Card = {
  id: number;
  last_digits: string;
  tos_accepted_at: string;
  paymode: CardPaymode;
};

type CardPaymode = {
  id: number;
  name: string;
};

export type ShippingAddress = {
  id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  phone_prefix: string;
  country: string;
  address: string;
  address2?: string;
  zip: string;
  city?: string;
  state?: string;
};

export type Currency = {
  id: number;
  name: string;
  symbol: string;
  conversion_ratio: number;
};

export type ExpressPlanTimer = {
  enabled: boolean;
  time_left: number | null;
  skippable: boolean;
};

export type User = {
  cid: string;
  id: string;
  type: 'users';
  access: string[];
  age: number;
  agreed_to_email_notifications_at?: string;
  authorized_via_facebook: boolean;
  body_parts_focus: {
    belly: '0' | '1';
    chest: '0' | '1';
    shins: '0' | '1';
    bottom: '0' | '1';
    thighs: '0' | '1';
    hands_and_shoulders: '0' | '1';
  };
  category: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  country: string;
  country_label: string;
  created_via_facebook: boolean;
  current_weight: number;
  current_weight_in_lbs: number;
  email: string;
  entered_password: boolean;
  fat_percentage: number;
  first_name: string;
  gdpr_accepted_at?: string;
  gender: Gender;
  goal: 'weightloss' | 'musclegain';
  goal_fat_percentage: number;
  goal_weight: number;
  goal_weight_in_lbs: number;
  has_active_meal_survey: boolean;
  height: number;
  height_feet: number;
  height_inches: number;
  last_name: string;
  locale: string;
  phone_number: string;
  phone_prefix: string;
  show_login_offer: boolean;
  state: UserState;
  subscription: Subscription | null;
  subscriptions: Subscription[];
  tos_accepted_at?: string;
  user_is_a_newbie: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tickets?: any;
  unit_system: UnitSystem;
  weight: string;
  weight_in_lbs: number;
  is_new_pro?: boolean;
  stats?: UserStatistics;
  medical_agreement_accepted: boolean;
  program_update: ProgramUpdate;
  testimonial_available: boolean;
  offer_banner_type?: string;
  cards: Card[] | null;
  latest_shipping_address: ShippingAddress | null;
  last_currency?: Currency;
  custom_fields?: UserCustomFields;
  latest_unsubscribe_flow_id: number | null;
  express_plan_delivery_timer?: ExpressPlanTimer;
};

export type UserCustomFields =
  | 'selected_chat_nutritionist'
  | 'chat_imitation_seen'
  | 'genom_popup_seen'
  | 'genom_popup_show_at'
  | 'pause_welcome_back_popup_seen';
