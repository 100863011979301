import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import App from 'components/App';
import ProgramPage from 'components/program/ProgramPage';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { themes } from 'helpers/themeHelper';

import {
  EnsureLoggedInWrapper,
  EnsureLoggedOutWrapper,
  EnsureUserReadyWrapper,
  EnsureUserDoNotNeedMealSurveyWrapper,
  EnsureUserIsPro,
  EnsureUserHasSeenLoginOffer,
  EnsureCleanStoreIfNeeded,
  EnsureUserHasSetPasswordWrapper,
  EnsureUserHasPhoneNumberWrapper,
  EnsureTheme,
  EnsureUserSeenUpsellOffer,
  EnsureFatBurnerAllowed,
  EnsureUnsubscribeV6Available,
  EnsureUserSeenAnnualProOffer,
  EnsureFastingDataFetched,
  EnsureFastingAllowed,
  EnsureSubTosAccepted,
  EnsureCollagenAllowed,
  EnsureCollagenActive,
  EnsureFatburnerActive,
  EnsureUnsubscribeV7Available,
  EnsureChatAvailability,
  EnsureUnsubscribeV8Enabled,
  EnsureUnsubscribeCommondataLoaded,
  EnsureAccessItemsLoaded,
  EnsureAcvGummiesAllowed,
  EnsureAcvGummiesActive,
  EnsureUnsubscribeReasonsLoaded,
  EnsureGenomPlusEnabled,
  EnsureGenomPlusPopupSeen,
  EnsureWorkoutsLoaded,
  EnsureKetoChocolateBarsAllowed,
  EnsureInternalWorkoutInprogress,
} from 'components/router';
import GenomPlusSurvey from 'pages/GenomPlusSurvey/GenomPlusSurvey';
import EnsureWorkoutsEnabled from 'components/router/EnsureWorkoutsEnabled';
import EnsureWorkoutsActive from 'components/router/EnsureWorkoutsActive';
import {
  WorkoutsUnsubscribePage,
  WorkoutsUnsubscribeReasonPage,
} from 'pages/WorkoutsUnsubscribe';

const LoginPage = React.lazy(() => import('components/auth/LoginPage'));
const DirectLoginPage = React.lazy(
  () => import('components/auth/DirectLoginPage')
);
const FacebookMergePage = React.lazy(
  () => import('components/auth/FacebookMergePage')
);
const RegisterPage = React.lazy(() => import('components/auth/RegisterPage'));
const RenewPasswordPage = React.lazy(
  () => import('components/auth/RenewPasswordPage')
);
const RenewPasswordSuccessPage = React.lazy(
  () => import('components/auth/RenewPasswordSuccessPage')
);
const RenewPasswordResetPage = React.lazy(
  () => import('components/auth/RenewPasswordResetPage')
);
const OfferPage = React.lazy(() => import('pages/OfferPage/OfferPage'));
const PostpaymentPage = React.lazy(() => import('pages/PostpaymentPage'));
const MealPage = React.lazy(() => import('components/program/MealPage'));
const MealChange = React.lazy(
  () => import('components/program/MealChange/MealChange')
);
const WorkoutPage = React.lazy(() => import('components/workout/WorkoutPage'));
const ExercisePreviewPage = React.lazy(
  () => import('components/workout/ExercisePreviewPage')
);
const WorkoutProgressPage = React.lazy(
  () => import('components/workout/WorkoutProgressPage')
);
const ShoppingListPage = React.lazy(
  () => import('components/program/ShoppingListPage')
);
const MealSurveyPage = React.lazy(
  () => import('components/meal_survey/MealSurveyPage')
);
const PreUnsubscribeV4Page = React.lazy(
  () => import('pages/PreUnsubscribeV4Page/PreUnsubscribeV4Page')
);
const UnsubscribeV4Page = React.lazy(
  () => import('pages/UnsubscribeV4Page/UnsubscribeV4Page')
);
const QuizPage = React.lazy(
  () => import('pages/UnsubscribeV6/QuizPage/QuizPage')
);
const ObjectionPage = React.lazy(
  () => import('pages/UnsubscribeV6/ObjectionPage/ObjectionPage')
);
const UnsubscribeV7Page = React.lazy(
  () => import('pages/UnsubscribeV7Page/UnsubscribeV7Page')
);
const UnsubscribeV8Page = React.lazy(
  () => import('pages/UnsubscribeV8Page/UnsubscribeV8Page')
);
const UnsubscribeReasonPage = React.lazy(
  () => import('pages/UnsubscribeReasonPage/UnsubscribeReasonPage')
);
const FatBurnerAddressPage = React.lazy(
  () => import('pages/FatBurnerAddressPage/FatBurnerAddressPage')
);
const FatBurnerSuccessPage = React.lazy(
  () => import('pages/FatBurnerSuccessPage/FatBurnerSuccessPage')
);
const FatBurnerSettingsPage = React.lazy(
  () => import('pages/FatBurnerSettingsPage/FatBurnerSettingsPage')
);
const FatBurnerAddressChangePage = React.lazy(
  () => import('pages/FatBurnerAddressChangePage/FatBurnerAddressChangePage')
);
const FatBurnerUnsubscribePage = React.lazy(
  () => import('pages/FatBurnerUnsubscribePage/FatBurnerUnsubscribePage')
);
const CollagenAddressPage = React.lazy(
  () => import('pages/CollagenAddressPage')
);
const CollagenSuccessPage = React.lazy(
  () => import('pages/CollagenSuccessPage')
);
const CollagenSettingsPage = React.lazy(
  () => import('pages/CollagenSettingsPage')
);
const CollagenAddressChangePage = React.lazy(
  () => import('pages/CollagenAddressChangePage')
);
const CollagenUnsubscribePage = React.lazy(
  () => import('pages/CollagenUnsubscribePage')
);
const AcvGummiesAddressPage = React.lazy(
  () => import('pages/AcvGummies/AcvGummiesAddressPage')
);
const AcvGummiesSuccessPage = React.lazy(
  () => import('pages/AcvGummies/AcvGummiesSuccessPage')
);
const AcvGummiesSettingsPage = React.lazy(
  () => import('pages/AcvGummies/AcvGummiesSettingsPage')
);
const AcvGummiesAddressChangePage = React.lazy(
  () => import('pages/AcvGummies/AcvGummiesAddressChangePage')
);
const AcvGummiesUnsubscribePage = React.lazy(
  () => import('pages/AcvGummies/AcvGummiesUnsubscribePage')
);
const SubscriptionContractConfirmationPage = React.lazy(
  () =>
    import(
      'components/subscription/contract/SubscriptionContractConfirmationPage'
    )
);
const SubscriptionContractPage = React.lazy(
  () => import('components/subscription/contract/SubscriptionContractPage')
);
const ProfilePage = React.lazy(() => import('components/user/ProfilePage'));
const EditProfilePage = React.lazy(
  () => import('components/user/EditProfilePage')
);
const EditGoalPage = React.lazy(() => import('components/user/EditGoalPage'));
const EditAccountPage = React.lazy(
  () => import('components/user/EditAccountPage/EditAccoutPage')
);
const NewPasswordPage = React.lazy(
  () => import('components/registration/NewPasswordPage')
);
const MedicalAgreementPage = React.lazy(
  () => import('components/registration/MedicalAgreement/MedicalAgreementPage')
);
const PhoneInputPage = React.lazy(
  () => import('components/registration/PhoneInputPage')
);
const CountryInputPage = React.lazy(
  () => import('components/registration/CountryInputPage')
);
const RegistrationPage = React.lazy(
  () => import('components/registration/RegistrationPage')
);
const ChatPage = React.lazy(() => import('pages/ChatPage'));
const PremiumChatPage = React.lazy(
  () => import('pages/PremiumChatPage/PremiumChatPage')
);
const PlanUpdatePage = React.lazy(
  () => import('components/planUpdate/PlanUpdatePage')
);
const TestimonialPage = React.lazy(
  () => import('components/testimonial/TestimonialPage')
);
const Summary = React.lazy(() => import('components/testimonial/main/Main'));
const Questions = React.lazy(
  () => import('components/testimonial/steps/Questions')
);
const Second = React.lazy(() => import('components/testimonial/steps/Second'));
const Prompt = React.lazy(() => import('components/testimonial/steps/Prompt'));
const Thanks = React.lazy(() => import('components/testimonial/steps/Thanks'));
const Terms = React.lazy(() => import('components/testimonial/terms/Terms'));
const TOSPage = React.lazy(() => import('components/public/TOSPage'));
const ConfirmLogin = React.lazy(() => import('components/auth/ConfirmLogin'));
const PremiumPage = React.lazy(() => import('components/premium/PremiumPage'));
const FastingPage = React.lazy(() => import('pages/FastingPage'));
const EatingPage = React.lazy(() => import('pages/EatingPage'));
const FastingCycleChangePage = React.lazy(
  () => import('pages/FastingCycleChangePage')
);
const ChooseFastingCycle = React.lazy(() => import('pages/ChooseFastingCycle'));
const EmailUnsusbscribe = React.lazy(
  () => import('pages/EmailUnsubscribe/EmailUnsusbscribe')
);
const GenomPlusSuccessPage = React.lazy(
  () => import('pages/GenomPlusSuccessPage/GenomPlusSuccessPage')
);
const GenomPlusSettingsPage = React.lazy(
  () => import('pages/GenomPlusSettingsPage/GenomPlusSettingsPage')
);
const GenomPlusUpdateCompletePage = React.lazy(
  () => import('pages/GenomPlusUpdateCompletePage/GenomPlusUpdateCompletePage')
);
const PauseSubscriptionPage = React.lazy(
  () => import('pages/PauseSubscriptionPage/PauseSubscriptionPage')
);
const WorkoutsStartPage = React.lazy(
  () => import('pages/Workouts/WorkoutsStartPage')
);
const WorkoutsInprogressPage = React.lazy(
  () => import('pages/Workouts/WorkoutsInprogressPage')
);
const WorkoutsCompletePage = React.lazy(
  () => import('pages/Workouts/WorkoutsCompletePage')
);
const KetoChocolateBarsAddressPage = React.lazy(
  () =>
    import('pages/KetoChocolateBarsAddressPage/KetoChocolateBarsAddressPage')
);

const unlessAuth = () => {
  if (localStorage.access_token) {
    return <Redirect to="/program" />;
  }

  return <LoginPage />;
};

export default (
  <App>
    <React.Suspense fallback={<LoadingAnimation />}>
      <Switch>
        <Route exact path="/direct_login/:token">
          <DirectLoginPage />
        </Route>
        <Route exact path="/direct_login/:user_id/:token">
          <DirectLoginPage />
        </Route>
        <Route exact path="/facebook_merge">
          <FacebookMergePage />
        </Route>
        <Route exact path="/register">
          <RegisterPage />
        </Route>
        <Route exact path="/login/confirm/:token">
          <ConfirmLogin />
        </Route>

        <Route path="/renew_password">
          <EnsureCleanStoreIfNeeded>
            <EnsureLoggedOutWrapper>
              <Route exact path="/renew_password">
                <RenewPasswordPage />
              </Route>
              <Route exact path="/renew_password/success">
                <RenewPasswordSuccessPage />
              </Route>
              <Route
                exact
                path="/renew_password/reset"
                render={(props) => <RenewPasswordResetPage {...props} />}
              />
            </EnsureLoggedOutWrapper>
          </EnsureCleanStoreIfNeeded>
        </Route>

        <Route exact path="/offer/direct/:offer_id">
          <OfferPage />
        </Route>
        <Route exact path="/postpayment" render={() => <PostpaymentPage />} />

        <Route path="/program">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <EnsureGenomPlusPopupSeen>
                          <Route
                            exact
                            path="/program"
                            render={(props) => <ProgramPage {...props} />}
                          />
                          <Route
                            exact
                            path="/program/:dayId/meal/:mealId"
                            component={() => <MealPage />}
                          />
                          <Route
                            exact
                            path="/program/:dayId/meal/:mealId/change"
                            component={() => <MealChange />}
                          />
                          <Route
                            exact
                            path="/program/:dayId/workout/:workoutId"
                            component={() => <WorkoutPage />}
                          />
                          <Route
                            exact
                            path="/program/:dayId/workout/:workoutId/exercise/:exerciseId"
                            component={() => <ExercisePreviewPage />}
                          />
                          <Route
                            exact
                            path="/program/workout/progress/:id"
                            component={() => <WorkoutProgressPage />}
                          />
                          <Route path="/program/shopping-list">
                            <ShoppingListPage />
                          </Route>
                        </EnsureGenomPlusPopupSeen>
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/premium">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <Route
                          path="/premium/:folderName?/:subFolderName?"
                          component={() => <PremiumPage />}
                        />
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/meal_survey">
          <EnsureLoggedInWrapper>
            {/* Draft users can not access these pages */}
            <EnsureUserReadyWrapper>
              {/* User can no access content if havent seen login offer */}
              <EnsureUserIsPro>
                <MealSurveyPage />
              </EnsureUserIsPro>
            </EnsureUserReadyWrapper>
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/sub_tos">
          <EnsureLoggedInWrapper>
            {/* User can no access content if havent seen login offer */}
            <EnsureUserIsPro>
              <SubscriptionContractConfirmationPage />
            </EnsureUserIsPro>
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/subscription_tos">
          <EnsureLoggedInWrapper>
            {/* Draft users can not access these pages */}
            <EnsureUserReadyWrapper>
              {/* User can no access content if havent seen login offer */}
              <EnsureUserIsPro>
                <SubscriptionContractPage />
              </EnsureUserIsPro>
            </EnsureUserReadyWrapper>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/offer">
          <EnsureLoggedInWrapper>
            {/* Draft users can not access these pages */}
            <EnsureUserReadyWrapper>
              <Route
                exact
                path="/offer/:offer_type/:meal_type"
                component={() => <OfferPage />}
              />
              <Route
                exact
                path="/offer/:offer_type"
                component={() => <OfferPage />}
              />
            </EnsureUserReadyWrapper>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/fat-burner-extract-address">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureFatBurnerAllowed>
                <Route
                  exact
                  path="/fat-burner-extract-address"
                  component={() => <FatBurnerAddressPage />}
                />
                <Route
                  exact
                  path="/fat-burner-extract-address/success"
                  component={() => <FatBurnerSuccessPage />}
                />
              </EnsureFatBurnerAllowed>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/collagen-address">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureCollagenAllowed>
                <Route
                  exact
                  path="/collagen-address"
                  component={() => <CollagenAddressPage />}
                />
                <Route
                  exact
                  path="/collagen-address/success"
                  component={() => <CollagenSuccessPage />}
                />
              </EnsureCollagenAllowed>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/apple-cider-vinegar-gummies-address">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureAcvGummiesAllowed>
                <Route
                  exact
                  path="/apple-cider-vinegar-gummies-address"
                  component={() => <AcvGummiesAddressPage />}
                />
                <Route
                  exact
                  path="/apple-cider-vinegar-gummies-address/success"
                  component={() => <AcvGummiesSuccessPage />}
                />
              </EnsureAcvGummiesAllowed>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/keto-chocolate-bars-address">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureKetoChocolateBarsAllowed>
                <Route
                  exact
                  path="/keto-chocolate-bars-address"
                  component={() => <KetoChocolateBarsAddressPage />}
                />
              </EnsureKetoChocolateBarsAllowed>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/user">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <Route
                          exact
                          path="/user"
                          component={() => <ProfilePage />}
                        />
                        <Route
                          exact
                          path="/user/profile/edit"
                          component={() => <EditProfilePage />}
                        />
                        <Route
                          exact
                          path="/user/goal/edit"
                          component={() => <EditGoalPage />}
                        />
                        <Route
                          exact
                          path="/user/account/edit"
                          component={() => <EditAccountPage />}
                        />
                        <Route
                          exact
                          path="/user/meal_survey/edit"
                          component={() => <MealSurveyPage />}
                        />
                        <Route path="/user/collagen-settings">
                          <EnsureCollagenAllowed>
                            <EnsureCollagenActive>
                              <Route
                                exact
                                path="/user/collagen-settings"
                                component={() => <CollagenSettingsPage />}
                              />
                              <Route
                                exact
                                path="/user/collagen-settings/address-edit"
                                component={() => <CollagenAddressChangePage />}
                              />
                              <Route
                                exact
                                path="/user/collagen-settings/unsubscribe"
                                component={() => <CollagenUnsubscribePage />}
                              />
                            </EnsureCollagenActive>
                          </EnsureCollagenAllowed>
                        </Route>
                        <Route path="/user/fatburner-settings">
                          <EnsureFatBurnerAllowed>
                            <EnsureFatburnerActive>
                              <Route
                                exact
                                path="/user/fatburner-settings"
                                component={() => <FatBurnerSettingsPage />}
                              />
                              <Route
                                exact
                                path="/user/fatburner-settings/address-edit"
                                component={() => <FatBurnerAddressChangePage />}
                              />
                              <Route
                                exact
                                path="/user/fatburner-settings/unsubscribe"
                                component={() => <FatBurnerUnsubscribePage />}
                              />
                            </EnsureFatburnerActive>
                          </EnsureFatBurnerAllowed>
                        </Route>
                        <Route path="/user/apple-cider-vinegar-gummies-settings">
                          <EnsureAcvGummiesAllowed>
                            <EnsureAcvGummiesActive>
                              <Route
                                exact
                                path="/user/apple-cider-vinegar-gummies-settings"
                                component={() => <AcvGummiesSettingsPage />}
                              />
                              <Route
                                exact
                                path="/user/apple-cider-vinegar-gummies-settings/address-edit"
                                component={() => (
                                  <AcvGummiesAddressChangePage />
                                )}
                              />
                              <Route
                                exact
                                path="/user/apple-cider-vinegar-gummies-settings/unsubscribe"
                                component={() => <AcvGummiesUnsubscribePage />}
                              />
                            </EnsureAcvGummiesActive>
                          </EnsureAcvGummiesAllowed>
                        </Route>
                        <Route exact path="/user/genomplus/settings">
                          <EnsureGenomPlusEnabled>
                            <GenomPlusSettingsPage />
                          </EnsureGenomPlusEnabled>
                        </Route>
                        <Route path="/user/workouts/unsubscribe">
                          <EnsureWorkoutsEnabled>
                            <EnsureWorkoutsActive>
                              <Route
                                exact
                                path="/user/workouts/unsubscribe"
                                component={() => <WorkoutsUnsubscribePage />}
                              />
                              <Route
                                exact
                                path="/user/workouts/unsubscribe/reason"
                              >
                                <EnsureUnsubscribeReasonsLoaded>
                                  <WorkoutsUnsubscribeReasonPage />
                                </EnsureUnsubscribeReasonsLoaded>
                              </Route>
                            </EnsureWorkoutsActive>
                          </EnsureWorkoutsEnabled>
                        </Route>
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/unsubscribe">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <Route
                          exact
                          path="/unsubscribe/unsubscribev4-step-1"
                          component={() => <PreUnsubscribeV4Page />}
                        />
                        <Route
                          exact
                          path="/unsubscribe/unsubscribev4-step-2"
                          component={() => <UnsubscribeV4Page />}
                        />
                        <Route path="/unsubscribe/unsubscribev6-quiz-:step">
                          <EnsureUnsubscribeV6Available>
                            <QuizPage />
                          </EnsureUnsubscribeV6Available>
                        </Route>
                        <Route path="/unsubscribe/unsubscribev6-objection-:step">
                          <EnsureUnsubscribeV6Available>
                            <ObjectionPage />
                          </EnsureUnsubscribeV6Available>
                        </Route>
                        <Route path="/unsubscribe/unsubscribev7-:step">
                          <EnsureUnsubscribeV7Available>
                            <UnsubscribeV7Page />
                          </EnsureUnsubscribeV7Available>
                        </Route>
                        <Route exact path="/unsubscribe/reason">
                          <EnsureUnsubscribeReasonsLoaded>
                            <UnsubscribeReasonPage />
                          </EnsureUnsubscribeReasonsLoaded>
                        </Route>
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/unsubscribev8">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <Route path="/unsubscribev8/:stepId">
                          <EnsureUnsubscribeV8Enabled>
                            <EnsureUnsubscribeCommondataLoaded>
                              <EnsureAccessItemsLoaded>
                                <UnsubscribeV8Page />
                              </EnsureAccessItemsLoaded>
                            </EnsureUnsubscribeCommondataLoaded>
                          </EnsureUnsubscribeV8Enabled>
                        </Route>
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/pause-subscription">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <PauseSubscriptionPage />
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/registration">
          <EnsureLoggedInWrapper>
            <Route exact path="/registration/new_password">
              <EnsureUserHasSetPasswordWrapper>
                <NewPasswordPage />
              </EnsureUserHasSetPasswordWrapper>
            </Route>

            <Route exact path="/registration/medical_agreement">
              <MedicalAgreementPage />
            </Route>

            <Route exact path="/registration/phone">
              <EnsureUserHasPhoneNumberWrapper>
                <PhoneInputPage />
              </EnsureUserHasPhoneNumberWrapper>
            </Route>

            <Route
              exact
              path="/registration/country"
              component={() => <CountryInputPage />}
            />

            <Route exact path="/registration">
              <RegistrationPage />
            </Route>
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/chat">
          <EnsureLoggedInWrapper>
            <EnsureChatAvailability>
              <ChatPage />
            </EnsureChatAvailability>
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/premium-commitment-chat">
          <EnsureLoggedInWrapper>
            <PremiumChatPage />
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/plan_update">
          <EnsureLoggedInWrapper>
            <EnsureTheme
              theme={[
                themes.diet,
                themes.keto,
                themes.ketogo,
                themes.fasting,
                themes.healthgenom,
              ]}
            >
              <PlanUpdatePage />
            </EnsureTheme>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/testimonial">
          <EnsureLoggedInWrapper>
            <EnsureTheme
              theme={[
                themes.diet,
                themes.keto,
                themes.ketogo,
                themes.fasting,
                themes.healthgenom,
              ]}
            >
              <TestimonialPage>
                <Route exact path="/testimonial/questions">
                  <Questions />
                </Route>
                <Route exact path="/testimonial/second">
                  <Second />
                </Route>
                <Route exact path="/testimonial/partake_prompt">
                  <Prompt />
                </Route>
                <Route exact path="/testimonial/thank_you">
                  <Thanks />
                </Route>
                <Route exact path="/testimonial/terms">
                  <Terms />
                </Route>
                <Route exact path="/testimonial">
                  <Summary />
                </Route>
              </TestimonialPage>
            </EnsureTheme>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/fasting-timer">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <EnsureFastingAllowed>
                          <Route exact path="/fasting-timer">
                            <EnsureFastingDataFetched>
                              <FastingPage />
                            </EnsureFastingDataFetched>
                          </Route>
                          <Route exact path="/fasting-timer/edit-fasting-cycle">
                            <EnsureFastingDataFetched>
                              <FastingCycleChangePage />
                            </EnsureFastingDataFetched>
                          </Route>
                        </EnsureFastingAllowed>
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/eating-timer">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <EnsureFastingAllowed>
                          <Route exact path="/eating-timer">
                            <EnsureFastingDataFetched>
                              <EatingPage />
                            </EnsureFastingDataFetched>
                          </Route>
                          <Route exact path="/eating-timer/edit-fasting-cycle">
                            <EnsureFastingDataFetched>
                              <FastingCycleChangePage />
                            </EnsureFastingDataFetched>
                          </Route>
                        </EnsureFastingAllowed>
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/choose-fasting-cycle">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureUserSeenUpsellOffer>
                <EnsureUserSeenAnnualProOffer>
                  <EnsureUserReadyWrapper>
                    <EnsureUserHasSeenLoginOffer>
                      <EnsureUserDoNotNeedMealSurveyWrapper>
                        <EnsureFastingAllowed>
                          <ChooseFastingCycle />
                        </EnsureFastingAllowed>
                      </EnsureUserDoNotNeedMealSurveyWrapper>
                    </EnsureUserHasSeenLoginOffer>
                  </EnsureUserReadyWrapper>
                </EnsureUserSeenAnnualProOffer>
              </EnsureUserSeenUpsellOffer>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/genomplus">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureGenomPlusEnabled>
                <Route exact path="/genomplus/success">
                  <GenomPlusSuccessPage />
                </Route>
                <Route exact path="/genomplus/update-complete">
                  <GenomPlusUpdateCompletePage />
                </Route>
                <Route path="/genomplus/survey">
                  <GenomPlusSurvey />
                </Route>
              </EnsureGenomPlusEnabled>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route path="/workouts">
          <EnsureLoggedInWrapper>
            <EnsureSubTosAccepted>
              <EnsureWorkoutsLoaded>
                <Route path="/workouts/start">
                  <WorkoutsStartPage />
                </Route>
                <Route path="/workouts/inprogress">
                  <EnsureInternalWorkoutInprogress>
                    <WorkoutsInprogressPage />
                  </EnsureInternalWorkoutInprogress>
                </Route>
                <Route path="/workouts/complete">
                  <WorkoutsCompletePage />
                </Route>
              </EnsureWorkoutsLoaded>
            </EnsureSubTosAccepted>
          </EnsureLoggedInWrapper>
        </Route>
        <Route exact path="/tos" component={() => <TOSPage />} />
        <Route exact path="/" component={unlessAuth} />
        <Route
          exact
          path="/email_unsubscribe"
          component={() => <EmailUnsusbscribe />}
        />
        {/* TODO: Add 404 screen */}
        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>
    </React.Suspense>
  </App>
);
