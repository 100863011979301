import { FastingData } from 'types/fasting';
import { Modals } from 'types/modals';
import { User } from 'types/user';
import { MealItem } from 'types/program';
import { OfferType } from 'types/offer';
import { UnsubscribeCommonData } from 'types/unsubscribe';
import { AccessItemsData, FreeAccessToUserData } from 'types/access';
import { GlobalDataStatus } from 'types/globalData';
import { GenomPlusSurveyStateType } from 'pages/GenomPlusSurvey/types';
import { InternalWorkoutState } from 'types/workouts';

export type InitialState = {
  user: User | null;
  day: object;
  diary: object;
  meal: MealItem | {};
  workout: object;
  messages: object[];
  error: object;
  session: {
    mergeErrorTitle: string;
    status: boolean;
    showMergeModal: boolean;
    incorrectPassword: boolean;
    facebookProfile: object;
  };
  chat: {
    cableEndpoint: {
      action: string;
      token: string;
      socket_channel: string;
      auth_endpoint: string;
      app_key: string;
      cluster: string;
    };
    isLoadingInitial: boolean;
    chatImitationSeen: boolean | null;
    selectedChatNutritionist: string | null;
    isConnectedToSocket: string;
  };
  isLoading: boolean;
  reachedFirstDay: boolean;
  reachedLastDay: boolean;
  allMessagesLoaded: boolean;
  diets: object[];
  productList: object;
  mealTags: object;
  calendar: object;
  validationMessage: object;
  surveyStep: number;
  offer: {
    data: OfferType | null;
    loadError: boolean;
  };
  invoice: object;
  mealSurvey: object;
  shoppingList: object;
  isConnectedToCable: string;
  modals: Modals[];
  fasting: FastingData | {};
  unsubscribeCommondata: UnsubscribeCommonData | {};
  accessItems: AccessItemsData | {};
  freeAccessToUser: FreeAccessToUserData | {};
  globalData: GlobalDataStatus | {};
  genomPlusSurvey: GenomPlusSurveyStateType;
  workouts: InternalWorkoutState | null;
};

const initialState: InitialState = {
  user: null,
  day: {},
  diary: {},
  meal: {},
  workout: {},
  messages: [],
  error: {},
  session: {
    mergeErrorTitle: '',
    status: !!localStorage.access_token,
    showMergeModal: false,
    incorrectPassword: false,
    facebookProfile: {},
  },
  chat: {
    cableEndpoint: {
      action: '',
      token: '',
      socket_channel: '',
      auth_endpoint: '',
      app_key: '',
      cluster: '',
    },
    isLoadingInitial: false,
    chatImitationSeen: null,
    selectedChatNutritionist: null,
    isConnectedToSocket: 'none',
  },
  isLoading: false,
  reachedFirstDay: false,
  reachedLastDay: false,
  allMessagesLoaded: false,
  diets: [],
  productList: {},
  mealTags: {},
  calendar: {},
  validationMessage: {},
  surveyStep: 1,
  offer: {
    data: null,
    loadError: false,
  },
  invoice: {},
  mealSurvey: {},
  shoppingList: {},
  isConnectedToCable: 'none',
  modals: [],
  fasting: {},
  unsubscribeCommondata: {},
  accessItems: {},
  freeAccessToUser: {},
  globalData: {
    isLoading: false,
    hasError: false,
    globalData: {
      unsubscribeReasons: null,
    },
  },
  genomPlusSurvey: {
    isLoading: false,
    error: null,
    data: null,
  },
  workouts: null,
};

export default initialState;
