import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isKetoChocolateBarsAllowed } from 'helpers/ketoChocolateBarsHelper';
import { User } from 'types/user';

type Props = {
  children: React.ReactNode;
};

function EnsureKetoChocolateBarsAllowed({ children }: Props) {
  const user = useSelector<any, User>((store) => store.rootReducer.user);

  if (!isKetoChocolateBarsAllowed(user)) {
    return <Redirect to="/program" />;
  }

  return <>{children}</>;
}

export default EnsureKetoChocolateBarsAllowed;
