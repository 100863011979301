import React from 'react';
import useTheme from 'helpers/themeHook';
import { isMaterialUI } from 'helpers/themeHelper';

import { Button, CircularProgress } from '@material-ui/core';

import base from './styles/SubmitButton.module.scss';
import diet from './styles/SubmitButton_diet.module.scss';
import keto from './styles/SubmitButton_keto.module.scss';
import ketogo from './styles/SubmitButton_ketogo.module.scss';
import fasting from './styles/SubmitButton_fasting.module.scss';
import healthgenom from './styles/SubmitButton_healthgenom.module.scss';

type Props = {
  label: string;
  type: string;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  className?: string;
};

const SubmitButton: React.FC<Props> = (props) => {
  const [s] = useTheme({ base, diet, keto, ketogo, fasting, healthgenom });

  const getBtnStateClass = () => {
    const { isLoading, disabled } = props;
    const btnStateClass = isLoading || disabled ? 'loading' : '';
    return btnStateClass;
  };

  const getButtonClass = () => {
    const { type } = props;
    let buttonClass = `form__submit_block__button ${s.button}`;
    if (getBtnStateClass() !== '') buttonClass += ` ${getBtnStateClass()}`;
    if (type) {
      buttonClass += ` ${type}`;
      type.split(' ').forEach((classname) => {
        buttonClass += ` ${s[classname]}`;
      });
    }
    return buttonClass;
  };

  const getPremiumUpsellClass = () => {
    const { type } = props;
    if (type) {
      if (type.includes('premiumUpsellAccept')) {
        return `${s.premiumUpsellAccept}`;
      }
      if (type.includes('premiumUpsellDecline')) {
        return `${s.premiumUpsellDecline}`;
      }
    }

    return false;
  };

  const getWrapperClass = () => {
    const { type, className } = props;
    let wrapperClass = 'form__submit_block';

    if (type) wrapperClass += ` ${type}`;

    wrapperClass += ` ${s.wrapper}`;
    const premiumClass = getPremiumUpsellClass();

    if (type?.includes('compact')) wrapperClass += `${s.compactButton}`;

    if (isMaterialUI()) {
      if (premiumClass) {
        wrapperClass += premiumClass;
      }
    }

    const additionalClass = className ? ` ${className}` : '';

    return `${wrapperClass}${additionalClass}`;
  };

  const getMaterialUIButtonClass = () => {
    if (disabled || isLoading) {
      return null;
    }

    switch (variant) {
      case 'outlined':
        return s.txtLarge;
      case 'text':
        return s.text;
      default:
        return s.standard;
    }
  };

  const { onClick, isLoading, disabled, label, type } = props;
  let { variant } = props;

  if (!variant) {
    variant = type === 'text-large' ? 'outlined' : 'contained';
  }

  if (isMaterialUI()) {
    return (
      <div className={getWrapperClass()}>
        <Button
          className={`${s.button} ${getMaterialUIButtonClass()}`}
          type="submit"
          onClick={onClick}
          disabled={isLoading || disabled}
          variant={variant}
          color="secondary"
        >
          {isLoading ? <CircularProgress size={25} color="secondary" /> : label}
        </Button>
      </div>
    );
  }

  return (
    <div className={getWrapperClass()}>
      <button
        className={getButtonClass()}
        type="submit"
        onClick={onClick}
        disabled={isLoading || disabled}
      >
        {isLoading ? (
          <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
            <circle id="loading-inner" cx={75} cy={75} r={60} />
          </svg>
        ) : (
          label
        )}
      </button>
    </div>
  );
};

export default SubmitButton;
